export default () => ({
    user: {
        id: '',
        email: '',
        login: '',
        token: localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
    },
    showModalAuthentication: false,
    showModalSaveCollection: false,
    messageAuthorization: '',
    messageRegistration: '',
})