import axios from "axios";
let domain = 'https://ns-touch.com/' 
// let domain = 'http://2016687.a3sky.web.hosting-test.net/'

export default {

    userAuthorization(params) {
        return axios.post(domain + '/wp-json/jwt-auth/v1/token',
            params
        ).then((response) => {
            return response.data
        }).catch(error => {
            return {error: error.response.data.message}
        })
    },
    userRegistration(params) {
        return axios.post(domain + '/wp-json/api/registration',
            params
        ).then((response) => {
            return response.data
        }).catch(error => {
            return {error: error.response.data.message}
        })
    },
    getUser(token) {
        return axios.get(domain + '/wp-json/api/get-user', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        }).then((response) => {
            return response.data
        })
    },
    saveCollection(params, token) {
        return axios.post(domain + '/wp-json/api/save-collection', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            },
            params
        }).then((response) => {
            return response.data
        })
    },
    resaveCollection(params, token) {
        return axios.post(domain + '/wp-json/api/resave-collection', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            },
            params
        }).then((response) => {
            return response.data
        })
    },
    getCollection(params, token) {
        return axios.get(domain + '/wp-json/api/get-collection', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            },
            params
        }).then((response) => {
            return response.data
        })
    },
    getCollections(params, token) {
        return axios.get(domain + '/wp-json/api/get-collections', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            },
            params
        }).then((response) => {
            return response.data
        })
    },
    sendToOrder(params, token) {
        return axios.get(domain + '/wp-json/api/send-to-order', {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            },
            params
        }).then((response) => {
            return response.data
        })
    },


}