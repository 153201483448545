<template>
  <div class="pop-up authentication" :class="{'opened': isVisible}">
    <div class="pop-up-content">
      <div class="pop-up-close" @click="closeModal">&#10006;</div>
        <h2>Save collection</h2>
        <form>
          <label class="text-primary">
            <p>collection name</p>
            <input @input="changeCollectionName($event)" type="text" name="collection_name" :value="collection_name" >
          </label>
          <div v-show="!collection_id" class="buttons-group">
            <button @click="save_collection" class="addToCollection__button">Save as PDF</button>
          </div>
          <div v-show="collection_id" class="buttons-group">
            <button @click="save_collection" class="addToCollection__button">Create new PDF</button>
            <button v-if="!collection_ordered" @click="resave_collection" class="addToCollection__button">Resave as PDF</button>
          </div>
          <p v-html="messageSaveCollection"/>
        </form>

    </div>
  </div>
</template>

<script>
    import {mapGetters, mapActions, mapMutations} from "vuex";

    export default {
        name: "SaveCollection",
        props: {
            isVisible: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                // new_collection_name: this.collection_name,
            }
        },
        computed: {
            ...mapGetters({
                messageSaveCollection: "Collection/messageSaveCollection",
                collection: "Collection/collection",
                collection_id: "Collection/collection_id",
                collection_name: "Collection/collection_name",
                collection_ordered: "Collection/collection_ordered",
                user: "Cabinet/user",
            })
        },
        methods: {
            ...mapActions({
                SAVE_COLLECTION: 'Collection/saveCollection',
                RESAVE_COLLECTION: 'Collection/resaveCollection',
            }),
            ...mapMutations({
                USER_AUTHORIZATION: "Cabinet/USER_AUTHORIZATION",
                USER_REGISTRATION: "Cabinet/USER_REGISTRATION",
                MODAL_SAVE_COLLECTION: "Cabinet/MODAL_SAVE_COLLECTION",
                CHANGE_COLLECTION_NAME: "Collection/CHANGE_COLLECTION_NAME",
                // SAVE_COLLECTION: "Cabinet/SAVE_COLLECTION",
                // addNote: "Cabinet/addNote",
            }),
            save_collection(event) {
                event.preventDefault()
                const params = {
                    user_id: this.user.id,
                    collection_name: this.collection_name,
                    collection: localStorage.getItem('collection') ? JSON.parse(localStorage.getItem('collection')): [],
                }
                // this.SAVE_COLLECTION(params)
                this.$emit('saveCollection', params)
                // this.$emit('saveAsPdf', params)
            },
            resave_collection(event) {
                event.preventDefault()
                const params = {
                    user_id: this.user.id,
                    collection_id: this.collection_id,
                    collection_name: this.collection_name,
                    collection: localStorage.getItem('collection') ? JSON.parse(localStorage.getItem('collection')): [],
                }
                // this.RESAVE_COLLECTION(params)
                this.$emit('resaveCollection', params)
                // this.$emit('saveAsPdf', params)
            },
            closeModal() {
                this.$emit('close')
            },
            changeCollectionName(event){
                this.CHANGE_COLLECTION_NAME({collection_name: event.target.value})
            }
        },
        beforeMount() {
            // debugger
        },
    }
</script>

<style lang="scss">
  .select-icon.opened {
    display: flex;
  }
</style>
