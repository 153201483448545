import mainApi from "../../api/main-api";

export default {
    getCollection({commit}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.getCollection(params, token).then(response => {
            commit('Collection/SAVE_COLLECTION', response, { root: true })
        });
    },
    getCollections({commit}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.getCollections(params, token).then(response => {
            commit('Collection/SAVE_COLLECTIONS', response, { root: true })
        });
    },
    saveCollection({commit, state}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.saveCollection(params, token).then(response => {
            state.messageSaveCollection = response.message
            commit('Collection/SAVE_COLLECTION_NAME', response, { root: true })
        });
    },
    resaveCollection({commit, state}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.resaveCollection(params, token).then(response => {
            state.messageSaveCollection = response.message
            commit('Collection/SAVE_COLLECTION_NAME', response, { root: true })
        });
    },
    sendToOrder({commit}, params) {
        let token = localStorage.getItem('token') && localStorage.getItem('token') !== 'undefined' ? JSON.parse(localStorage.getItem('token')) : []
        mainApi.sendToOrder(params, token).then(response => {
            commit('Collection/SAVE_COLLECTION', response, { root: true })
        });
    },
}
