export default {
    SET_SELECTED_SWITCH_PALETTE(state, payload) {
        state.selectedSwitchPalette = payload
    },
    SET_SELECTED_COMBO_PALETTE(state, payload) {
        state.selectedComboPalette = payload
    },
    SET_TOUCH_PARAMETERS(state, payload) {
        state.touchParameters = payload
    },
    SET_FIELD_TOUCH_PARAMETERS(state, {key, value}) {
        state.touchParameters[key] = value
    },
    SET_COMBO_PARAMETERS(state, payload) {
        state.comboParameters = payload
    },
    SET_ICONS(state, payload) {
        state.icons = payload
    },
    SET_UPDATE_ITEM(state, payload) {
        state.updateItem = payload
    },
    UPDATE_ICONS(state, {index, value}) {
        state.icons[index - 1] = value
    },
    RESET_PARAMETERS(state) {
        state.touchParameters = {
            series: '80',
            interface: null,
            inlay: null,
            sensorsCount: 4,
            orientation: null,
            surface: null,
            switchesCount: 1,
            palette: null,
            material: 'stone'
        }
        state.selectedSwitchPalette = {}
        state.icons = []
        state.updateItem = {
            index: -1,
            name: ''
        }
    },
    SET_AS_DEFAULT_PARAMETERS(state) {
        state.touchParameters = {
            series: state.touchParameters.series,
            interface: "DRY CONTACT",
            inlay: "Brass",
            sensorsCount: state.touchParameters.sensorsCount,
            orientation: "Vertical",
            surface: "Glossy",
            switchesCount: 1,
            palette: "Deep Nocturne",
            material: 'stone'
        }
        state.selectedSwitchPalette = {
            dataArticul: "DN",
            dataIconColor: "white",
            paletteImg: "url(/img/deep_nocturne.eccd693b.jpg)",
            value: "Deep Nocturne",
        }
        state.icons = ["71", "72", "73", "74", "75", "76", "77", "78"]
    },
    RESET_PARAMETERS_COMBO(state) {
        state.comboParameters = {
            series: '80',
            slotsCount: 2,
            orientation: "Horizontal",
            material: 'stone',
            surface: null,
            comboCount: 1,
            palette: null,
            // switch params
            switchSensorsCount: null,
            switchOrientation: null,
            switchInlay: null,
            switchInterface: null,
            // socket params
            colorSocket: 'NF',
            selectedSlots: {
                1: null,
                2: null,
                3: null,
                4: null
            },
            slotsTouchIcons: {
                1: [],
                2: [],
                3: [],
                4: []
            }
        }
        state.selectedComboPalette = {}
        state.updateItem = {
            index: -1,
            name: ''
        }
    },
    SET_AS_DEFAULT_PARAMETERS_COMBO(state, payload) {
        console.log(payload)
        state.comboParameters = {
            series: state.comboParameters.series,
            slotsCount: state.comboParameters.slotsCount,
            orientation: "Horizontal",
            material: 'stone',
            surface: "Glossy",
            comboCount: 1,
            palette: "Deep Nocturne",
            // switch params
            switchSensorsCount: null,
            switchOrientation: null,
            switchInlay: "Brass",
            switchInterface: "DRY CONTACT",
            // socket params
            colorSocket: 'NF',
            selectedSlots: {
                1: '04',
                2: 'S1',
                3: 'S1',
                4: 'S1',
                5: 'S1'
            },
            slotsTouchIcons: {
                1: ["71", "72", "73", "74"],
                2: [],
                3: [],
                4: [],
                5: []
            }
        }

        state.selectedSwitchPalette = {}
        state.selectedComboPalette = {
            dataArticul: "DN",
            dataIconColor: "white",
            paletteImg: "url(/img/deep_nocturne.eccd693b.jpg)",
            value: "Deep Nocturne",
        }
        state.icons = []
        state.updateItem = {
            index: -1,
            name: ''
        }

        if (state.comboParameters.series === '90') {
            state.comboParameters.colorSocket = "BM"
            state.comboParameters.selectedSlots = {
                1: '04',
                2: 'R1',
                3: 'R1',
                4: 'R1',
                5: 'R1'
            }
            // if(state.comboParameters.slotsCount == 2){
            //     state.comboParameters.selectedSlots = {
            //         1: '04',
            //         2: 'R1',
            //         3: 'R1',
            //         4: 'R1',
            //         5: 'R1'
            //     }
            // }
            // if(state.comboParameters.slotsCount == 3){
            //     state.comboParameters.selectedSlots = {
            //         1: '04',
            //         2: '04',
            //         3: 'R1',
            //         4: 'R1',
            //         5: 'R1'
            //     }
            //     state.comboParameters.slotsTouchIcons = {
            //         1: ["71", "72", "73", "74"],
            //         2: ["75", "76", "77", "78"],
            //         3: [],
            //         4: [],
            //         5: []
            //     }
            // }

        }
        if (state.comboParameters.series === 'frames') {
            state.comboParameters.colorSocket = "BM"
            state.comboParameters.selectedSlots = {
                1: '04',
                2: 'R1',
                3: 'R1',
                4: 'R1',
                5: 'R1'
            }
        }
        if (payload.mode === "sockets"){
            if (state.comboParameters.series === '90'){
                state.comboParameters.selectedSlots = {
                    1: 'R1',
                    2: 'R1',
                    3: 'R1',
                    4: 'R1',
                    5: 'R1'
                }
            }else {
                state.comboParameters.selectedSlots = {
                    1: 'S1',
                    2: 'S1',
                    3: 'S1',
                    4: 'S1',
                    5: 'S1'
                }
            }

        }
    }
}
